// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-thank-you-index-js": () => import("./../../../src/pages/thank-you/index.js" /* webpackChunkName: "component---src-pages-thank-you-index-js" */),
  "component---src-templates-docs-index-js": () => import("./../../../src/templates/docs/index.js" /* webpackChunkName: "component---src-templates-docs-index-js" */),
  "component---src-templates-docs-landing-index-js": () => import("./../../../src/templates/docs-landing/index.js" /* webpackChunkName: "component---src-templates-docs-landing-index-js" */),
  "component---src-templates-features-js": () => import("./../../../src/templates/features.js" /* webpackChunkName: "component---src-templates-features-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-how-it-works-index-js": () => import("./../../../src/templates/how-it-works/index.js" /* webpackChunkName: "component---src-templates-how-it-works-index-js" */)
}

